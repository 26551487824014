import {
	AfterViewInit,
	Component,
	ElementRef,
	ViewChild,
	effect,
	input,
	output,
	signal,
	untracked
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Group, Tween } from '@tweenjs/tween.js';
import {
	AmbientLight,
	Box3,
	Color,
	DirectionalLight,
	Material,
	Mesh,
	MeshPhysicalMaterial,
	MeshStandardMaterial,
	PerspectiveCamera,
	PlaneGeometry,
	Raycaster,
	RepeatWrapping,
	Scene,
	Sphere,
	TextureLoader,
	Vector2,
	Vector3,
	WebGLRenderer
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { GenderApiEnum } from '../generated-models';
import { AppLoaderService } from '../layout';
import { BodyAreaType, GetBodyAreaByName, GetBodyAreaBySophosId } from '../shared/helpers/body-areas.helper';
import {
	CreateMaterialWithClickableHitboxes,
	FemaleHitboxesList,
	GetAllHitboxes,
	GetCenterPoint,
	GetHitbox,
	GetHitboxesFromAreaList,
	GetMostSpecificBodyAreaFromHitboxes,
	GetShortestBoundingBoxSide,
	MaleHitboxesList,
	UpdateBiffSkinMaterialUniforms
} from './biff.hitboxes';

import clamp from 'lodash-es/clamp';

// light color for 4000 to 5000 lumens makes it looks like it's in a doctor's office
const ambientLightColor = '#ffffff';
const defaultSkinColor = '#f4c99e';
const skyColor = '#ccd7db';
const defaultGreyoutColor = 'black';
const defaultProblemColor = 'red';
const minCameraDistance = 0.25;
const maxCameraDistance = 8;
const cameraZoom = 3; // Everything gets distored at default zoom 1 if you get close to the model
const minDecalRadius = 0.01;
const maxDecalRadius = 2.0;

const startingCameraTarget = new Vector3(0, 0.9, 0);

export interface DecalData {
	point: Vector3;
	scale: number;
	selected: boolean;
}

export interface BiffClickedEvent {
	decal: DecalData;
	bodyArea: BodyAreaType;
}

export interface BiffDecalResizedEvent {
	decal: DecalData;
}

@Component({
	selector: 'biff',
	standalone: true,
	imports: [MatButtonToggleModule, MatButtonModule, MatProgressSpinnerModule],
	templateUrl: './biff.component.html',
	styleUrl: './biff.component.scss'
})
export class BiffComponent implements AfterViewInit {
	@ViewChild('canvas') canvasRef!: ElementRef<HTMLCanvasElement>;

	private tweensGroup: Group = new Group();

	private scene!: Scene;
	private camera!: PerspectiveCamera;
	private renderer!: WebGLRenderer;
	private controls!: OrbitControls;
	private visiblePerson!: Mesh;
	private femaleMesh!: Mesh;
	private maleMesh!: Mesh;
	private plane!: Mesh;
	private directionalLight!: DirectionalLight;
	private skinMaterial!: MeshPhysicalMaterial;

	public modelsHaveFinishedLoading = signal(false);
	public skinMaterialIsLoaded = signal(false);

	public gender = input<GenderApiEnum.Male | GenderApiEnum.Female>(GenderApiEnum.Male);
	public skinColor = input<string>(defaultSkinColor);
	public problemColor = input<string>(defaultProblemColor);
	public greyoutColor = input<string>(defaultGreyoutColor);
	public clickableBodyAreas = input<BodyAreaType[] | null | undefined>();
	public disabled = input<boolean>(false);
	public decalData = input<DecalData[]>([]);
	public displayMode = input<'view' | 'edit'>('edit');

	public onClicked = output<BiffClickedEvent>();
	public onDecalResize = output<BiffDecalResizedEvent>();

	public mouseWheelBehaviorMode = signal<'zoom' | 'resize'>('resize');

	constructor(private _appLoaderService: AppLoaderService) {
		// shows and hides everything while the page is loading
		effect(() => {
			const appIsReady = !this._appLoaderService.isProcessing();
			const biffIsReady = this.modelsHaveFinishedLoading();
			if (appIsReady && biffIsReady) {
				this.maleMesh.visible = true;
				this.femaleMesh.visible = true;
				this.plane.visible = true;
			}
		});

		// Switches out the mesh if a different gender is selected
		effect(() => {
			const genderState = this.gender();
			if (untracked(this.modelsHaveFinishedLoading)) {
				requestAnimationFrame(() => {
					if (genderState === GenderApiEnum.Male && this.visiblePerson !== this.maleMesh) {
						this.scene.remove(this.visiblePerson);
						this.visiblePerson = this.maleMesh;
						this.scene.add(this.visiblePerson);
						FemaleHitboxesList.forEach(x => {
							this.scene.remove(x);
						});
						MaleHitboxesList.forEach(x => {
							this.scene.add(x);
						});
					} else if (genderState === GenderApiEnum.Female && this.visiblePerson !== this.femaleMesh) {
						this.scene.remove(this.visiblePerson);
						this.visiblePerson = this.femaleMesh;
						this.scene.add(this.visiblePerson);
						MaleHitboxesList.forEach(x => {
							this.scene.remove(x);
						});
						FemaleHitboxesList.forEach(x => {
							this.scene.add(x);
						});
					}

					(this.visiblePerson.material as Material).needsUpdate = true;
				});
			}
		});

		// Changes the Shaders that draws the diabled areas and selected areas
		// Whenever anything changes that requires those to be regenerated;
		effect(() => {
			const gender = this.gender();
			const clickableBodyAreas = this.clickableBodyAreas();
			const greyoutColor = this.greyoutColor();
			const skinColor = this.skinColor();
			const problemColor = this.problemColor();
			const decalData = this.decalData();
			const skinMaterialIsLoaded = this.skinMaterialIsLoaded();
			if (skinMaterialIsLoaded) {
				UpdateBiffSkinMaterialUniforms(
					this.skinMaterial,
					skinColor,
					clickableBodyAreas,
					gender,
					greyoutColor,
					problemColor,
					decalData
				);
			}
		});

		effect(() => {
			const decalData = this.decalData();
			const mode = this.displayMode();
			const loaded = this.modelsHaveFinishedLoading();
			if (mode == 'view' && loaded) {
				this.zoomToDecals(decalData);
			}
		});

		// Disabled and enables orbit control features
		// Based on what mode is set
		effect(() => {
			const disabled = this.disabled();
			const mode = this.mouseWheelBehaviorMode();
			const displayMode = this.displayMode();
			if (untracked(this.modelsHaveFinishedLoading)) {
				requestAnimationFrame(() => {
					this.controls.enableRotate = !disabled;
					this.controls.enableZoom = (mode == 'zoom' || displayMode == 'view') && !disabled;
				});
			}
		});

		// Handle changing the zoom when the selectable body areas change
		// Should zoom to a place that lets you see all body areas.
		// A body will be created to hold all the meshes, and then we will zoom to that box
		effect(() => {
			const clickableBodyAreas = this.clickableBodyAreas();
			const gender = this.gender();
			const useDefaultZoom = clickableBodyAreas == null || clickableBodyAreas.length == 0;
			if (untracked(this.modelsHaveFinishedLoading)) {
				requestAnimationFrame(() => {
					if (useDefaultZoom) {
						this.resetZoom(gender);
						return;
					}
					const boundingBox = this.getMultipleBodyPartZoomBox(clickableBodyAreas, gender);
					const finalSize = boundingBox.getSize(new Vector3());
					if (finalSize.x > 0 && finalSize.y > 0 && finalSize.z > 0) {
						this.zoomToBox(boundingBox);
					} else {
						this.resetZoom(gender);
					}
				});
			}
		});
	}

	private getMultipleBodyPartZoomBox(
		bodyAreaTypes: BodyAreaType[] | null | undefined,
		gender: GenderApiEnum.Female | GenderApiEnum.Male
	): Box3 {
		const boundingBox = new Box3();
		bodyAreaTypes?.forEach(bodyAreaType => {
			const hitbox = GetHitbox(gender, bodyAreaType);
			if (hitbox) {
				boundingBox.expandByObject(hitbox);
			} else {
				const parentName = GetBodyAreaBySophosId(GetBodyAreaByName(bodyAreaType).parendId ?? 1).name;
				const parentHitbox = GetHitbox(gender, parentName);
				if (parentHitbox) {
					boundingBox.expandByObject(parentHitbox);
				}
			}
		});
		return boundingBox;
	}

	private getBodyPartZoomFactor(
		bodyAreaType: BodyAreaType,
		gender: GenderApiEnum.Male | GenderApiEnum.Female
	) {
		let hitbox = GetHitbox(gender, bodyAreaType);
		if (!hitbox) {
			const parentBodyArea = GetBodyAreaBySophosId(GetBodyAreaByName(bodyAreaType).parendId ?? 1).name;
			hitbox = GetHitbox(gender, parentBodyArea);
		}
		return this.getMeshZoomFactor(hitbox as Mesh);
	}

	private getBoundingBoxZoomFactor(boundingBox: Box3, screenFillFactor: number = 0.9): number {
		// Calculate the maximum size of the bounding box
		const size = boundingBox.getSize(new Vector3());
		const maxSize = Math.max(size.x, size.y, size.z);
		// Convert camera FOV to radians
		const fov = this.camera.fov * (Math.PI / 180);
		// Factor to control how much of the screen the object should occupy
		const fillFactor = screenFillFactor / this.camera.zoom;
		// Calculate the necessary distance to fit `maxSize` in the viewport according to the FOV and fillFactor
		return maxSize / (2 * Math.tan(fov / 2) * fillFactor);
	}

	private getMeshZoomFactor(hitboxMesh: Mesh): number {
		// Create a bounding box around the hitbox object
		const box = new Box3().setFromObject(hitboxMesh);
		return this.getBoundingBoxZoomFactor(box);
	}

	private zoomToBox(boundingBox: Box3) {
		const zoomFactor = this.getBoundingBoxZoomFactor(boundingBox);
		const center = boundingBox.getCenter(new Vector3());
		this.zoomTo(zoomFactor, center);
	}

	private zoomToDecals(decals: DecalData[]) {
		const box = new Box3();
		decals.forEach(d => {
			const sphereBoundingBox = new Sphere(d.point, d.scale).getBoundingBox(new Box3());
			box.union(sphereBoundingBox);
		});
		const zoomFactor = this.getBoundingBoxZoomFactor(box, 0.4);
		const center = box.getCenter(new Vector3());
		this.zoomTo(zoomFactor, center);
	}

	private zoomToBodyPart(
		selectedBodyAreaType: BodyAreaType | null,
		gender: GenderApiEnum.Male | GenderApiEnum.Female
	) {
		let target = selectedBodyAreaType;
		let zoomHitbox = GetHitbox(gender, target);
		while (!zoomHitbox && target) {
			const bodyArea = GetBodyAreaByName(target);
			target = GetBodyAreaBySophosId(bodyArea?.parendId ?? 1).name;
			zoomHitbox = GetHitbox(gender, target);
		}
		const desiredZoom = this.getBodyPartZoomFactor(target ?? 'Skin', gender);
		const desiredTarget = zoomHitbox ? GetCenterPoint(zoomHitbox) : startingCameraTarget;

		this.zoomTo(desiredZoom, desiredTarget);
	}

	public zoomTo(targetDistance: number, desiredTarget?: Vector3 | null) {
		const animationTime = 500;
		const cam = this.camera;
		const controls = this.controls;
		const initialDistance = cam.position.distanceTo(this.controls.target);

		this.tweensGroup.add(
			new Tween({ distance: initialDistance, target: controls.target })
				.to({ distance: targetDistance, target: desiredTarget ?? controls.target }, animationTime)
				.onUpdate(function (object) {
					const direction = new Vector3().subVectors(cam.position, object.target).normalize();

					cam.position.copy(direction).multiplyScalar(object.distance).add(object.target);
					cam.lookAt(object.target);
					controls.update();
				})
				.start()
		);
	}

	resetZoom(gender: GenderApiEnum.Male | GenderApiEnum.Female) {
		this.zoomToBodyPart(null, gender);
	}

	async initThree() {
		// Load assets
		const textureLoader = new TextureLoader();
		const objLoader = new OBJLoader();
		const baseFolder = 'assets';
		const [female, male, floorTexture, floorNormalMap] = await Promise.all([
			objLoader.loadAsync(`${baseFolder}/Realistic_Female_Low_Poly.obj`),
			objLoader.loadAsync(`${baseFolder}/Realistic_Male_Low_Poly.obj`),
			textureLoader.loadAsync(`${baseFolder}/Basic-Tile.png`),
			textureLoader.loadAsync(`${baseFolder}/Basic-Tile-Normal-Map.png`)
		]);

		const elem = this.canvasRef?.nativeElement as HTMLCanvasElement;
		const container = elem.parentElement as HTMLElement;

		// Set up the renderer
		this.renderer = new WebGLRenderer({
			canvas: this.canvasRef?.nativeElement,
			alpha: true,
			antialias: true
		});
		this.renderer.localClippingEnabled = true;
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.shadowMap.enabled = true;
		this.renderer.setClearColor(0x000000, 0);
		this.renderer.setSize(container.clientWidth, container.clientHeight);

		this.scene = new Scene();
		this.scene.background = new Color(skyColor);

		const tileCount = 16;
		const anisotropy = Math.min(8, this.renderer.capabilities.getMaxAnisotropy());
		[floorTexture, floorNormalMap].forEach(tex => {
			tex.wrapT = RepeatWrapping;
			tex.wrapS = RepeatWrapping;
			tex.repeat.set(tileCount, tileCount);
			tex.anisotropy = anisotropy;
		});

		const geometry = new PlaneGeometry(16, 16);
		const floorMaterial = new MeshStandardMaterial({
			map: floorTexture,
			normalMap: floorNormalMap,
			normalScale: new Vector2(4, 4)
		});
		this.plane = new Mesh(geometry, floorMaterial);
		this.plane.receiveShadow = true;
		this.plane.visible = !this._appLoaderService.isProcessing();
		this.plane.rotateX(Math.PI * -0.5);
		this.scene.add(this.plane);

		// Set up the Camera
		// Notes on Orbit Control + Camera Relations:
		//
		// 1) The "Zoom" for the orbit controls only uses camera position
		// actual camera zoom should never change.
		//
		// 2) If you change the camera in code in any way, you need to
		// call, this.controls.update(); to keep the controls in sync
		const cam = new PerspectiveCamera(45, elem.clientWidth / elem.clientHeight, 0.1, 1000);
		cam.position.set(0, 0.8, 8);
		cam.aspect = elem.clientWidth / elem.clientHeight;
		cam.zoom = cameraZoom;
		cam.updateProjectionMatrix();
		this.camera = cam;

		this.controls = this.setupController();

		this.skinMaterial = CreateMaterialWithClickableHitboxes(
			this.skinColor(),
			this.clickableBodyAreas(),
			this.gender(),
			this.greyoutColor(),
			this.problemColor(),
			this.decalData(),
			() => {
				this.skinMaterialIsLoaded.set(true);
			}
		);

		// make the male and female models the same height
		// This makes it to where they can share more hitboxes
		const maleScaleFactor = 0.0245;
		const femaleScaleFactor = 0.026;

		const meshes: Mesh[] = [];
		[
			{ model: male, scale: maleScaleFactor },
			{ model: female, scale: femaleScaleFactor }
		].forEach(item => {
			const { model, scale } = item;
			model.traverse(node => {
				if (node instanceof Mesh) {
					// This will smooth the mesh
					node.geometry.deleteAttribute('normal');
					node.geometry = mergeVertices(node.geometry);
					node.geometry.computeVertexNormals();

					node.castShadow = true;
					node.scale.set(scale, scale, scale);

					node.material = this.skinMaterial;

					meshes.push(node);
					return;
				}
			});
		});

		[this.maleMesh, this.femaleMesh] = meshes;
		if (this._appLoaderService.isProcessing()) {
			this.maleMesh.visible = false;
			this.femaleMesh.visible = false;
		}
		this.visiblePerson = this.gender() === GenderApiEnum.Male ? this.maleMesh : this.femaleMesh;

		this.scene.add(this.visiblePerson);

		this.setupRoomLighting();
		this.setupHitboxes();
		this.setupEvents();

		this.renderer.setAnimationLoop(() => this.animate());

		// Handle starting zoom
		const mode = this.displayMode();
		if (mode == 'edit') {
			const boundingBox = this.getMultipleBodyPartZoomBox(this.clickableBodyAreas(), this.gender());
			const finalSize = boundingBox.getSize(new Vector3());
			if (finalSize.x > 0 && finalSize.y > 0 && finalSize.z > 0) {
				this.zoomToBox(boundingBox);
			} else {
				this.resetZoom(this.gender());
			}
		} else if (mode == 'view') {
			this.zoomToDecals(this.decalData());
		}

		this.modelsHaveFinishedLoading.set(true);
	}

	animate() {
		const cam = this.camera;

		// Set the directional light to the top right of the camera
		const forward = cam.getWorldDirection(new Vector3());
		const right = new Vector3().crossVectors(forward, cam.up).normalize();
		const offset = new Vector3().addScaledVector(cam.up, 2).addScaledVector(right, 1);
		this.directionalLight.position.copy(cam.position).add(offset);

		// Do animations established by tween
		this.tweensGroup.update();

		// update the controls
		this.controls.update();

		// update the renderer
		this.renderer.render(this.scene, cam);
	}

	setupRoomLighting() {
		const ambientLight = new AmbientLight(ambientLightColor, 0.9);
		this.scene.add(ambientLight);

		this.directionalLight = new DirectionalLight(ambientLightColor, 1.8);
		this.directionalLight.target = this.visiblePerson;
		this.directionalLight.castShadow = true;
		this.directionalLight.position.set(1, 1, 1);
		const shadowCam = this.directionalLight.shadow.camera;
		const shadowWidth = 0.7;
		shadowCam.top = 2;
		shadowCam.bottom = -0.1;
		shadowCam.left = shadowWidth;
		shadowCam.right = -shadowWidth;
		// blur the edges of the shadows just a bit
		this.directionalLight.shadow.radius = 10;
		// default shadow resolution is 512 / 512
		this.directionalLight.shadow.mapSize.set(2048, 2048);
		this.scene.add(this.directionalLight);
	}

	setupHitboxes() {
		GetAllHitboxes(this.gender()).forEach(hitBoxInfo => {
			this.scene.add(hitBoxInfo);
		});
	}

	setupController() {
		// Set up the user input controls
		const lockedPolarAngle = Math.PI / 2;
		const controls = new OrbitControls(this.camera, this.renderer.domElement);

		controls.enableDamping = true;
		controls.maxPolarAngle = lockedPolarAngle; // Lock the controls so turning can only happen along the Y axis
		controls.minPolarAngle = lockedPolarAngle;
		controls.maxDistance = maxCameraDistance;
		controls.minDistance = minCameraDistance;
		controls.enablePan = true;
		controls.enableZoom = this.mouseWheelBehaviorMode() == 'zoom' || this.displayMode() == 'view';
		controls.enableRotate = !this.disabled();
		controls.target.copy(startingCameraTarget);

		// limit the pan
		const v = new Vector3();
		const justAboveTheFloor = 0.1;
		const justAboveBiffsHead = 1.8;
		const farEnoughAway = 1.5;
		const minPan = new Vector3(-farEnoughAway, justAboveTheFloor, -farEnoughAway);
		const maxPan = new Vector3(farEnoughAway, justAboveBiffsHead, farEnoughAway);

		controls.addEventListener('change', () => {
			v.copy(controls.target);
			controls.target.clamp(minPan, maxPan);
			v.sub(controls.target);
			this.camera.position.sub(v);
		});

		return controls;
	}

	setupEvents() {
		// Resize the canvas whenever the canvas wrapper changes size.
		new ResizeObserver((entries: ResizeObserverEntry[]) => {
			for (const entry of entries) {
				if (entry.contentRect) {
					this.renderer.setSize(entry.contentRect.width, entry.contentRect.height);
					this.camera.aspect = entry.contentRect.width / entry.contentRect.height;
					this.camera.updateProjectionMatrix();
				}
			}
			this.controls.update();
		}).observe(this.canvasRef.nativeElement.parentElement as HTMLElement);

		// Resize the selected area and zoom to meet it
		this.canvasRef.nativeElement.addEventListener('wheel', (e: WheelEvent) => {
			if (this.mouseWheelBehaviorMode() != 'resize' || this.disabled() || this.displayMode() == 'view') {
				return;
			}

			e.preventDefault();

			const decalData = this.decalData();
			const selected = decalData.find(x => x.selected);
			if (selected) {
				const tickSize = 0.01;
				const currentScale = selected.scale;
				const skipUpdate =
					(e.deltaY < 0 && currentScale == maxDecalRadius) || (e.deltaY >= 0 && currentScale == minDecalRadius);
				if (skipUpdate) {
					return;
				}
				const clampedScale = clamp(
					e.deltaY < 0 ? currentScale + tickSize : currentScale - tickSize,
					minDecalRadius,
					maxDecalRadius
				);

				selected.scale = clampedScale;
				this.onDecalResize.emit({
					decal: selected
				});
			}
		});

		this.canvasRef.nativeElement.addEventListener('click', (e: MouseEvent) => {
			const rect = this.canvasRef.nativeElement.getBoundingClientRect();

			// Calculate mouse position relative to the canvas
			const xPos = e.clientX - rect.left;
			const yPos = e.clientY - rect.top;

			// normalize the mouse position
			const normX = (xPos / rect.width) * 2 - 1;
			const normY = -(yPos / rect.height) * 2 + 1;
			const normalizedMousePosition = new Vector2(normX, normY);

			const rayCaster = new Raycaster();
			rayCaster.setFromCamera(normalizedMousePosition, this.camera);

			// Get intersections of biff as well as all of the hitboxes
			const hitboxes = GetAllHitboxes(this.gender());
			const allIntersections = rayCaster.intersectObjects([this.visiblePerson, ...hitboxes]);
			const clickableHitboxIntersections = rayCaster.intersectObjects(
				GetHitboxesFromAreaList(this.gender(), this.clickableBodyAreas() ?? ['Skin'])
			);
			const biffIntersectionIndex = allIntersections.findIndex(i => i.object === this.visiblePerson);

			if (clickableHitboxIntersections.length > 0 && biffIntersectionIndex > -1) {
				const biffIntersection = allIntersections[biffIntersectionIndex];
				const intersectionsUntilBiff = allIntersections.slice(0, biffIntersectionIndex);

				if (intersectionsUntilBiff.length > 0 && biffIntersection.face) {
					const bodyAreaType = GetMostSpecificBodyAreaFromHitboxes(
						clickableHitboxIntersections.map(x => x.object as Mesh)
					);

					// Get size of selected area based on hitbox
					const bodyAreaHitbox = GetHitbox(this.gender(), bodyAreaType) as Mesh;
					const largestDefault = 0.15;
					const selectionSize = Math.min(GetShortestBoundingBoxSide(bodyAreaHitbox) * 0.65, largestDefault);

					console.log(clickableHitboxIntersections, biffIntersection, this.clickableBodyAreas());

					this.onClicked.emit({
						decal: {
							point: biffIntersection.point,
							scale: selectionSize,
							selected: true
						},
						bodyArea: bodyAreaType
					});
				}
			}
		});
	}

	public onModeToggle(event: MatButtonToggleChange) {
		this.mouseWheelBehaviorMode.set(event.value);
	}

	public ngAfterViewInit() {
		this.initThree();
	}
}
