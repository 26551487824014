import keyBy from 'lodash-es/keyBy';
import orderBy from 'lodash-es/orderBy';
import range from 'lodash-es/range';
import {
	BoxGeometry,
	Color,
	ColorRepresentation,
	Matrix4,
	Mesh,
	MeshPhysicalMaterial,
	MeshStandardMaterial,
	Vector3
} from 'three';
import { GenderApiEnum } from '../generated-models';
import { BodyAreaType, GetBodyAreaByName } from '../shared/helpers/body-areas.helper';
import { DecalData } from './biff.component';

// Useful For debugging
const showAllHitboxes = false;
const specificHitboxesToShow: BodyAreaType[] = [];
const redColor = new Color(1, 0, 0);

const maxShaderArraySize = 50;
const fillerVector = new Vector3();
const fillerMatrix = new Matrix4();

const GetShaderHitboxUniforms = (
	clickableBodyTypes: BodyAreaType[] | null | undefined,
	gender: GenderApiEnum.Male | GenderApiEnum.Female
): {
	positions: Vector3[];
	scales: Vector3[];
	rotations: Matrix4[];
	hitboxLength: number;
	skipDarkening: boolean;
} => {
	const returnMe = {
		positions: range(0, maxShaderArraySize).map(() => fillerVector),
		scales: range(0, maxShaderArraySize).map(() => fillerVector),
		rotations: range(0, maxShaderArraySize).map(() => fillerMatrix),
		hitboxLength: 0,
		skipDarkening: !clickableBodyTypes
	};
	if (!clickableBodyTypes || clickableBodyTypes.length == 0) {
		return returnMe;
	}

	const hitboxMeshes: Mesh[] = GetHitboxesFromAreaList(gender, clickableBodyTypes);
	if (hitboxMeshes.length == 0) {
		return returnMe;
	}

	hitboxMeshes.forEach((item, i) => {
		if (i >= maxShaderArraySize) {
			return;
		}
		returnMe.positions[i] = item.position;

		const geo = item.geometry as BoxGeometry;
		const size = geo.parameters;
		returnMe.scales[i] = new Vector3(size.width, size.height, size.depth);

		const rotationMatrix = new Matrix4().makeRotationFromEuler(item.rotation);
		const inverseRotationMatrix = new Matrix4().copy(rotationMatrix).invert();
		returnMe.rotations[i] = inverseRotationMatrix;
	});

	returnMe.hitboxLength = Math.min(hitboxMeshes.length, maxShaderArraySize);
	return returnMe;
};

const GetDecalUniforms = (
	decalData: DecalData[]
): { positions: Vector3[]; scales: number[]; selected: boolean[]; length: number } => {
	const returnMe = {
		positions: range(0, maxShaderArraySize).map(() => fillerVector),
		scales: range(0, maxShaderArraySize).map(() => 0),
		selected: range(0, maxShaderArraySize).map(() => false),
		length: 0
	};

	orderBy(decalData, item => item.selected).forEach((item, i) => {
		if (i >= maxShaderArraySize) {
			return;
		}
		returnMe.positions[i] = item.point;
		returnMe.scales[i] = item.scale;
		returnMe.selected[i] = item.selected;
	});

	returnMe.length = Math.min(decalData.length, maxShaderArraySize);

	return returnMe;
};

export const CreateMaterialWithClickableHitboxes = (
	skinColor: ColorRepresentation,
	clickableBodyTypes: BodyAreaType[] | null | undefined,
	gender: GenderApiEnum.Female | GenderApiEnum.Male,
	greyoutColor: string,
	decalColor: string,
	decalData: DecalData[],
	materialLoadedCallback: () => void
): MeshPhysicalMaterial => {
	const material = new MeshPhysicalMaterial({
		color: skinColor,
		thickness: 0.01
	});

	material.onBeforeCompile = function (shader) {
		material.userData['uniforms'] = shader.uniforms;
		UpdateBiffSkinMaterialUniforms(
			material,
			skinColor,
			clickableBodyTypes,
			gender,
			greyoutColor,
			decalColor,
			decalData
		);

		shader.vertexShader = `
				varying vec3 vWorldPosition;
				${shader.vertexShader}
			`.replace(
			`#include <begin_vertex>`,
			`#include <begin_vertex>
				vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
			`
		);

		shader.fragmentShader = `
			varying vec3 vWorldPosition;

			uniform bool skipDarkening;
			uniform int hitboxLength;
			uniform vec3 hitboxPositions[${maxShaderArraySize}];
			uniform vec3 hitboxScales[${maxShaderArraySize}];
			uniform mat4 hitboxRotationMatrices[${maxShaderArraySize}];

			uniform int decalLength;
			uniform vec3 decalPositions[${maxShaderArraySize}];
			uniform float decalScales[${maxShaderArraySize}];
			uniform bool decalSelected[${maxShaderArraySize}];

			uniform vec3 disabledColor;
			uniform vec3 decalColor;

			float roundedBoxSDF(vec3 localPosition, vec3 hitboxScale, float radius) {
				vec3 halfScale = hitboxScale * 0.5;
				vec3 q = abs(localPosition) - halfScale + vec3(radius);
				return length(max(q, 0.0)) + min(max(q.x, max(q.y, q.z)), 0.0) - radius;
			}

			float sphereSDF(vec3 pixelPosition, vec3 sphereCenter, float radius) {
				return length(pixelPosition - sphereCenter) - radius;
			}
	
		${shader.fragmentShader}
	`.replace(
			`#include <dithering_fragment>`,
			`
				float edgeWidth = 0.001;

				if(!skipDarkening) {
					float roundness = 0.01;
					bool pixelInEnabledBox = false;
					float minDistance = 10000.0;
					for (int i = 0; i < hitboxLength; i++) {
						vec3 hitboxPosition = hitboxPositions[i];
						vec3 hitboxScale = hitboxScales[i];
						mat4 hitboxRotationMatrix = hitboxRotationMatrices[i];
						vec3 translatedPosition = vWorldPosition - hitboxPosition;
						vec3 localPosition = (hitboxRotationMatrix * vec4(translatedPosition, 1.0)).xyz;
				
						// Compute distance to the rounded box
						float dist = roundedBoxSDF(localPosition, hitboxScale, roundness);
						minDistance = min(minDistance, dist);
						
						// Check if the point is within the rounded hitbox
						if (dist < 0.0) {
							pixelInEnabledBox = true;
							break;
						}
					}

					// Use smoothstep to blend the color near the edges
					if (!pixelInEnabledBox) {
						float edgeFactor = smoothstep(0.0, edgeWidth, minDistance);
						gl_FragColor.rgb = mix(gl_FragColor.rgb, disabledColor, edgeFactor * 0.25);
					}
				}

				for (int i = 0; i < decalLength; i++) {
					vec3 decalPosition = decalPositions[i];
					float decalScale = decalScales[i];
					bool isDecalSelected = decalSelected[i];

					float dist = sphereSDF(vWorldPosition, decalPosition, decalScale);

					if (dist < edgeWidth) {
						float shineFactor = .3;
						float smallerNumberIsMoreMetal = 4.0;

						vec3 viewDir = normalize(vViewPosition);
						vec3 lightDir = normalize(directionalLights[0].direction);
						vec3 halfwayDir = normalize(lightDir + viewDir);
						float specular = pow(max(dot(normal, halfwayDir), 0.0), smallerNumberIsMoreMetal) * shineFactor;

						float alpha = smoothstep(0.0, -edgeWidth, dist);
						gl_FragColor.rgb = mix(gl_FragColor.rgb, decalColor + specular, (isDecalSelected ? .75 : 0.3) * alpha);
						break;
					}
				}
						
				#include <dithering_fragment>
			`
		);

		materialLoadedCallback();
	};

	return material;
};

export const UpdateBiffSkinMaterialUniforms = (
	materialToUpdate: MeshPhysicalMaterial,
	skinColor: ColorRepresentation,
	clickableBodyTypes: BodyAreaType[] | null | undefined,
	gender: GenderApiEnum.Female | GenderApiEnum.Male,
	greyoutColor: string,
	decalColor: string,
	decalData: DecalData[]
) => {
	materialToUpdate.color.set(skinColor);

	const uniforms = materialToUpdate.userData['uniforms'];
	if (uniforms) {
		const hitboxUnforms = GetShaderHitboxUniforms(clickableBodyTypes, gender);
		const decalUniforms = GetDecalUniforms(decalData);

		uniforms['hitboxLength'] = { value: hitboxUnforms.positions.length };
		uniforms['hitboxPositions'] = { value: hitboxUnforms.positions };
		uniforms['hitboxScales'] = { value: hitboxUnforms.scales };
		uniforms['hitboxRotationMatrices'] = { value: hitboxUnforms.rotations };
		uniforms['skipDarkening'] = { value: hitboxUnforms.skipDarkening };

		uniforms['decalLength'] = { value: decalData.length };
		uniforms['decalPositions'] = { value: decalUniforms.positions };
		uniforms['decalScales'] = { value: decalUniforms.scales };
		uniforms['decalSelected'] = { value: decalUniforms.selected };

		uniforms['disabledColor'] = { value: new Color(greyoutColor) };
		uniforms['decalColor'] = { value: new Color(decalColor) };
	}
};

const HitboxMaterial = new MeshStandardMaterial({
	color: redColor,
	wireframe: true
});

const hitboxMaterialHidden = new MeshStandardMaterial({
	color: redColor,
	wireframe: true,
	visible: showAllHitboxes
});

const bodyAreaUDKey = 'bodyArea';

const degreesToRads = (degrees: number): number => {
	return degrees * 0.017453292519943295;
};

const createHitbox = (
	bodyAreaType: BodyAreaType,
	size: Vector3,
	pos: Vector3,
	xRotationDegrees?: number,
	yRotationDegrees?: number,
	zRotationDegrees?: number
): Mesh => {
	const geometry = new BoxGeometry(size.x, size.y, size.z);
	const hitBoxMesh = new Mesh(
		geometry,
		specificHitboxesToShow.includes(bodyAreaType) ? HitboxMaterial : hitboxMaterialHidden
	);

	// Now we can get the hitbox type
	hitBoxMesh.userData[bodyAreaUDKey] = bodyAreaType;
	hitBoxMesh.position.copy(pos);
	if (xRotationDegrees) {
		hitBoxMesh.rotateX(degreesToRads(xRotationDegrees));
	}
	if (yRotationDegrees) {
		hitBoxMesh.rotateY(degreesToRads(yRotationDegrees));
	}
	if (zRotationDegrees) {
		hitBoxMesh.rotateZ(degreesToRads(zRotationDegrees));
	}

	return hitBoxMesh;
};

const mirrorX = (vec: Vector3): Vector3 => {
	const mirrored = vec.clone();
	mirrored.setX(mirrored.x * -1);
	return mirrored;
};

const armSize = new Vector3(0.2, 0.56, 0.18);
const armZRotation = 15;
const leftArmPos = new Vector3(0.27, 1.2, -0.05);
const rightArmPos = mirrorX(leftArmPos);

const legSize = new Vector3(0.22, 0.69, 0.28);
const legXRotation = 5;
const leftLegPos = new Vector3(0.11, 0.45, -0.033);
const rightLegPos = mirrorX(leftLegPos);

const handSize = new Vector3(0.1, 0.26, 0.12);
const leftHandPos = new Vector3(0.37, 0.82, 0.0);
const rightHandPos = mirrorX(leftHandPos);

const fingerZThickness = 0.023;
const fingerSize = new Vector3(0.053, 0.1, fingerZThickness);
const fingerY = 0.775;
const fingerRelativeX = 0.385;
const indexFingerZPos = 0.0425;

const leftIndexFingerPos = new Vector3(fingerRelativeX, fingerY, indexFingerZPos);
const leftMiddleFingerPos = new Vector3(fingerRelativeX, fingerY, indexFingerZPos - fingerZThickness * 1);
const leftRingFingerPos = new Vector3(fingerRelativeX, fingerY, indexFingerZPos - fingerZThickness * 2);
const leftPinkyFingerPos = new Vector3(fingerRelativeX, fingerY, indexFingerZPos - fingerZThickness * 3);
const rightIndexFingerPos = mirrorX(leftIndexFingerPos);
const rightMiddleFingerPos = mirrorX(leftMiddleFingerPos);
const rightRingFingerPos = mirrorX(leftRingFingerPos);
const rightPinkyFingerPos = mirrorX(leftPinkyFingerPos);

const thumbSize = new Vector3(0.04, 0.12, 0.06);
const leftThumbPos = new Vector3(0.34, 0.845, 0.02);
const rightThumbPos = mirrorX(leftThumbPos);

const eyeSize = new Vector3(0.047, 0.034, 0.055);
const leftEyePos = new Vector3(0.035, 1.655, 0.1);
const rightEyePos = mirrorX(leftEyePos);

const earSize = new Vector3(0.04, 0.06, 0.05);
const leftEarPos = new Vector3(0.08, 1.63, 0.01);
const rightEarPos = mirrorX(leftEarPos);

const footSize = new Vector3(0.18, 0.25, 0.32);
const leftFootPos = new Vector3(0.12, 0.0, 0.03);
const rightFootPos = mirrorX(leftFootPos);

const bigToeSize = new Vector3(0.036, 0.04, 0.11);
const leftBigToePos = new Vector3(0.12, 0.012, 0.11);
const rightBigToePos = mirrorX(leftBigToePos);

const toeThickness = 0.0162;
const toeSize = new Vector3(toeThickness, 0.04, 0.09);
const toe2XPos = 0.145;
const toeYPos = 0.012;
const toeZPos = 0.11;
const toeYRotation = 6;
const leftSecondToePos = new Vector3(toe2XPos, toeYPos, toeZPos);
const leftThirdToePos = new Vector3(toe2XPos + toeThickness * 1, toeYPos, toeZPos);
const leftFourthToePos = new Vector3(toe2XPos + toeThickness * 2, toeYPos, toeZPos);
const leftPinkyToePos = new Vector3(toe2XPos + toeThickness * 3, toeYPos, toeZPos);
const rightSecondToePos = mirrorX(leftSecondToePos);
const rightThirdToePos = mirrorX(leftThirdToePos);
const rightFourthToePos = mirrorX(leftFourthToePos);
const rightPinkyToePos = mirrorX(leftPinkyToePos);

// Note: Some Body Areas do not have hitboxes

// These are because
// - we can't see them yet
//   Tongue
//   Teeth
// - they would connect disjointed children
//   Etremeties
//   Eyes
//   Ears
// - They are too general and would overlap other hitboxes
//   Skin (would overlap everything)
//   Lips (would overlap mouth)

export const MaleHitboxesList: Mesh[] = [
	createHitbox('Skin', new Vector3(0.9, 1.8, 0.5), new Vector3(0, 0.88, 0)),
	createHitbox('Head', new Vector3(0.2, 0.195, 0.26), new Vector3(0, 1.661, 0.044), 23),
	createHitbox('Nose', new Vector3(0.03, 0.065, 0.07), new Vector3(0, 1.63, 0.12)),
	createHitbox('Mouth', new Vector3(0.07, 0.027, 0.08), new Vector3(0, 1.575, 0.1)),
	createHitbox('Neck', new Vector3(0.2, 0.1, 0.2), new Vector3(0, 1.52, 0.0), 23),
	createHitbox('Chest', new Vector3(0.35, 0.25, 0.2), new Vector3(0, 1.33, 0.05)),
	createHitbox('Abdomen', new Vector3(0.35, 0.25, 0.3), new Vector3(0, 1.08, 0.0)),
	createHitbox('Genitourinary', new Vector3(0.18, 0.15, 0.2), new Vector3(0, 0.88, 0.025)),

	createHitbox('Left Eye', eyeSize, leftEyePos),
	createHitbox('Left Ear', earSize, leftEarPos),
	createHitbox('Left Arm', armSize, leftArmPos, 0, 0, armZRotation),
	createHitbox('Left Hand', handSize, leftHandPos),
	createHitbox('Left Thumb', thumbSize, leftThumbPos),
	createHitbox('Left Index Finger', fingerSize, leftIndexFingerPos),
	createHitbox('Left Middle Finger', fingerSize, leftMiddleFingerPos),
	createHitbox('Left Ring Finger', fingerSize, leftRingFingerPos),
	createHitbox('Left Pinky Finger', fingerSize, leftPinkyFingerPos),
	createHitbox('Left Leg', legSize, leftLegPos, legXRotation),
	createHitbox('Left Foot', footSize, leftFootPos),
	createHitbox('Left Big Toe', bigToeSize, leftBigToePos, 0, toeYRotation),
	createHitbox('Left Second Toe', toeSize, leftSecondToePos, 0, toeYRotation),
	createHitbox('Left Third Toe', toeSize, leftThirdToePos, 0, toeYRotation),
	createHitbox('Left Fourth Toe', toeSize, leftFourthToePos, 0, toeYRotation),
	createHitbox('Left Pinky Toe', toeSize, leftPinkyToePos, 0, toeYRotation),

	createHitbox('Right Eye', eyeSize, rightEyePos),
	createHitbox('Right Ear', earSize, rightEarPos),
	createHitbox('Right Arm', armSize, rightArmPos, 0, 0, -armZRotation),
	createHitbox('Right Hand', handSize, rightHandPos),
	createHitbox('Right Thumb', thumbSize, rightThumbPos),
	createHitbox('Right Index Finger', fingerSize, rightIndexFingerPos),
	createHitbox('Right Middle Finger', fingerSize, rightMiddleFingerPos),
	createHitbox('Right Ring Finger', fingerSize, rightRingFingerPos),
	createHitbox('Right Pinky Finger', fingerSize, rightPinkyFingerPos),
	createHitbox('Right Leg', legSize, rightLegPos, legXRotation),
	createHitbox('Right Foot', footSize, rightFootPos),
	createHitbox('Right Big Toe', bigToeSize, rightBigToePos),
	createHitbox('Right Second Toe', toeSize, rightSecondToePos, 0, -toeYRotation),
	createHitbox('Right Third Toe', toeSize, rightThirdToePos, 0, -toeYRotation),
	createHitbox('Right Fourth Toe', toeSize, rightFourthToePos, 0, -toeYRotation),
	createHitbox('Right Pinky Toe', toeSize, rightPinkyToePos, 0, -toeYRotation)
];

// Make positional tweaks for the female model
export const FemaleHitboxesList: Mesh[] = MaleHitboxesList.map(hitBoxItem => {
	const newItem: Mesh = hitBoxItem.clone();
	const pos = newItem.position;
	const x = pos.x;
	const y = pos.y;
	const z = pos.z;
	const bigToeMovement = 0.037;
	const otherToesMovement = 0.035;
	switch (newItem.userData[bodyAreaUDKey]) {
		case 'Head':
			pos.setZ(z - 0.02);
			break;
		case 'Left Ear':
		case 'Right Ear':
			pos.setZ(z - 0.025);
			break;
		case 'Left Eye':
		case 'Right Eye':
			pos.setZ(z - 0.01);
			pos.setY(y - 0.01);
			break;
		case 'Left Index Finger':
		case 'Left Middle Finger':
		case 'Left Ring Finger':
		case 'Left Pinky Finger':
		case 'Right Index Finger':
		case 'Right Middle Finger':
		case 'Right Ring Finger':
		case 'Right Pinky Finger':
			pos.setY(y + 0.01);
			pos.setZ(z + 0.005);
			break;
		case 'Left Big Toe':
			pos.setX(x - bigToeMovement);
			break;
		case 'Right Big Toe':
			pos.setX(x + bigToeMovement);
			break;
		case 'Left Leg':
		case 'Right Leg':
			pos.setY(y + 0.02);
			break;
		case 'Left Second Toe':
		case 'Left Third Toe':
		case 'Left Fourth Toe':
		case 'Left Pinky Toe':
			pos.setX(x - otherToesMovement);
			break;
		case 'Right Second Toe':
		case 'Right Third Toe':
		case 'Right Fourth Toe':
		case 'Right Pinky Toe':
			pos.setX(x + otherToesMovement);
	}
	return newItem;
});

export const GetHitboxesFromAreaList = (
	gender: GenderApiEnum.Male | GenderApiEnum.Female,
	bodyAreaTypes: BodyAreaType[]
): Mesh[] => {
	const getHitboxesFromBodyAreaType = (
		gender: GenderApiEnum.Male | GenderApiEnum.Female,
		type: BodyAreaType
	): Mesh[] => {
		const myHitbox = GetHitbox(gender, type);
		const result = myHitbox ? [myHitbox] : [];
		const children = GetBodyAreaByName(type).children;
		if (children && children.length > 0) {
			children
				.map(child => getHitboxesFromBodyAreaType(gender, child.name))
				.flat()
				.forEach(item => {
					result.push(item);
				});
		}
		return result;
	};

	const hitboxMeshes: Mesh[] = [];

	bodyAreaTypes.forEach(type => {
		getHitboxesFromBodyAreaType(gender, type).forEach(x => {
			hitboxMeshes.push(x);
		});
	});

	return hitboxMeshes;
};

export const GetAllHitboxes = (gender: GenderApiEnum.Male | GenderApiEnum.Female): Mesh[] => {
	return gender === GenderApiEnum.Female ? FemaleHitboxesList : MaleHitboxesList;
};

const HitboxDictionaries: Record<GenderApiEnum.Male | GenderApiEnum.Female, Record<BodyAreaType, Mesh>> = {
	[GenderApiEnum.Male]: keyBy(MaleHitboxesList, (m: Mesh) => m.userData[bodyAreaUDKey]) as Record<
		BodyAreaType,
		Mesh
	>,
	[GenderApiEnum.Female]: keyBy(FemaleHitboxesList, (m: Mesh) => m.userData[bodyAreaUDKey]) as Record<
		BodyAreaType,
		Mesh
	>
};

export const GetHitbox = (
	gender: GenderApiEnum.Male | GenderApiEnum.Female,
	bodyArea: BodyAreaType | null
): Mesh | null => {
	if (!bodyArea) {
		return null;
	}
	return HitboxDictionaries[gender][bodyArea];
};

export const HitboxContainsPoint = (hitbox: Mesh, point: Vector3): boolean => {
	const localPoint = point.clone();
	hitbox.worldToLocal(localPoint);

	if (!hitbox.geometry.boundingBox) {
		hitbox.geometry.computeBoundingBox();
	}

	return hitbox.geometry.boundingBox?.containsPoint(localPoint) ?? false;
};

export const GetHitboxBodyArea = (hitbox: Mesh): BodyAreaType => {
	return hitbox.userData[bodyAreaUDKey] as BodyAreaType;
};

export const GetMostSpecificBodyAreaFromHitboxes = (hitboxes: Mesh[]): BodyAreaType => {
	let currentMostSpecificBodyArea: BodyAreaType = 'Skin';
	let smallest = Infinity;
	hitboxes.forEach(hitbox => {
		const bbSize = GetBoundingBoxSize(hitbox);
		const area = bbSize.x * bbSize.y * bbSize.z;
		if (area < smallest) {
			currentMostSpecificBodyArea = GetHitboxBodyArea(hitbox);
			smallest = area;
		}
	});
	return currentMostSpecificBodyArea;
};

export const GetCenterPoint = (mesh: Mesh): Vector3 => {
	const middle = new Vector3();
	if (mesh.geometry) {
		if (!mesh.geometry.boundingBox) {
			mesh.geometry.computeBoundingBox();
		}
		mesh.geometry.boundingBox?.getCenter(middle);
		mesh.localToWorld(middle);
	}
	return middle;
};

export const GetBoundingBoxSize = (mesh: Mesh): Vector3 => {
	const geom = mesh.geometry;
	if (!geom.boundingBox) {
		geom.computeBoundingBox();
	}
	const boxSize = geom.boundingBox?.getSize(new Vector3());
	return boxSize as Vector3;
};

export const GetShortestBoundingBoxSide = (mesh: Mesh): number => {
	const boxSize = GetBoundingBoxSize(mesh);
	return Math.min(boxSize.x, boxSize.y, boxSize.z);
};
