<div [class.disabled-overlay]="disabled()"></div>
@if (!modelsHaveFinishedLoading()) {
	<div class="spinner-container">
		<mat-spinner></mat-spinner>
	</div>
}
<canvas #canvas></canvas>
@if (displayMode() == 'edit') {
	<div class="controls-container">
		<button mat-flat-button (click)="resetZoom(gender())">Reset Zoom</button>
		<label>Mouse Wheel</label>
		<mat-button-toggle-group
			name="mouse wheel mode"
			aria-label="mouse wheel mode"
			[vertical]="true"
			[value]="mouseWheelBehaviorMode()"
			(change)="onModeToggle($event)">
			<mat-button-toggle value="resize">Resize</mat-button-toggle>
			<mat-button-toggle value="zoom">Zoom</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
}
