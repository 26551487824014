// This all needs to stay in sync with the database.
// Can do 'npm run generate-body-area-seed-data'
// whenever this changes.

import keyBy from 'lodash-es/keyBy';

const BodyAreaTypeList = [
	'Head',
	'Neck',
	'Chest',
	'Abdomen',
	'Genitourinary',
	'Extremities',
	'Skin',
	'Mouth',
	'Nose',
	'Ears',
	'Eyes',
	'Left Eye',
	'Right Eye',
	'Left Ear',
	'Right Ear',
	'Arms',
	'Legs',
	'Hands',
	'Feet',
	'Left Arm',
	'Right Arm',
	'Left Leg',
	'Right Leg',
	'Left Hand',
	'Right Hand',
	'Left Thumb',
	'Left Index Finger',
	'Left Middle Finger',
	'Left Ring Finger',
	'Left Pinky Finger',
	'Right Thumb',
	'Right Index Finger',
	'Right Middle Finger',
	'Right Ring Finger',
	'Right Pinky Finger',
	'Left Foot',
	'Right Foot',
	'Left Big Toe',
	'Left Second Toe',
	'Left Third Toe',
	'Left Fourth Toe',
	'Left Pinky Toe',
	'Right Big Toe',
	'Right Second Toe',
	'Right Third Toe',
	'Right Fourth Toe',
	'Right Pinky Toe'
] as const;

Object.freeze(BodyAreaTypeList);

export type AllBodyAreaTypes = typeof BodyAreaTypeList;
export type BodyAreaType = AllBodyAreaTypes[number];

export interface IBodyArea {
	id: number;
	name: BodyAreaType;
	snomedCTCode: string;
	snomedCTDescription: string;
	parendId?: number;
	children?: IBodyArea[];
}

// TODO: Tony - Make the C# the source of truth for these codable concepts
// custom-scripts/generate-body-area-seed-data.ts uses this object
export const BodyAreas: IBodyArea[] = [
	{
		id: 1,
		name: 'Head',
		snomedCTCode: '406121007',
		snomedCTDescription: 'Head region structure',
		children: [
			{
				id: 8,
				name: 'Mouth',
				snomedCTCode: '123851003',
				snomedCTDescription: 'Mouth region structure'
			},
			{
				id: 9,
				name: 'Nose',
				snomedCTCode: '120578006',
				snomedCTDescription: 'Nose part'
			},
			{
				id: 10,
				name: 'Ears',
				snomedCTCode: '117590005',
				snomedCTDescription: 'Ear structure',
				children: [
					{
						id: 16,
						name: 'Left Ear',
						snomedCTCode: '726675003',
						snomedCTDescription: 'Structure of left eye region'
					},
					{
						id: 17,
						name: 'Right Ear',
						snomedCTCode: '726680007',
						snomedCTDescription: 'Structure of right eye region'
					}
				]
			},
			{
				id: 11,
				name: 'Eyes',
				snomedCTCode: '371398005',
				snomedCTDescription: 'Eye region structure',
				children: [
					{
						id: 18,
						name: 'Left Eye',
						snomedCTCode: '726675003',
						snomedCTDescription: 'Structure of left eye region'
					},
					{
						id: 19,
						name: 'Right Eye',
						snomedCTCode: '726680007',
						snomedCTDescription: 'Structure of right eye region'
					}
				]
			}
		]
	},
	{
		id: 2,
		name: 'Neck',
		snomedCTCode: '45048000',
		snomedCTDescription: 'Neck structure'
	},
	{
		id: 3,
		name: 'Chest',
		snomedCTCode: '51185008',
		snomedCTDescription: 'Thoracic structure'
	},
	{
		id: 4,
		name: 'Abdomen',
		snomedCTCode: '113345001',
		snomedCTDescription: 'Abdominal structure'
	},
	{
		id: 5,
		name: 'Genitourinary',
		snomedCTCode: '71934003',
		snomedCTDescription: 'Genital structure'
	},
	{
		id: 6,
		name: 'Extremities',
		snomedCTCode: '66019005',
		snomedCTDescription: 'Limb structure',
		children: [
			{
				id: 12,
				name: 'Arms',
				snomedCTCode: '5312000',
				snomedCTDescription: 'Upper limb structure',
				children: [
					{
						id: 20,
						name: 'Left Arm',
						snomedCTCode: '762211005',
						snomedCTDescription: 'Structure of part of left upper limb'
					},
					{
						id: 21,
						name: 'Right Arm',
						snomedCTCode: '762212003',
						snomedCTDescription: 'Structure of part of right upper limb'
					}
				]
			},
			{
				id: 13,
				name: 'Legs',
				snomedCTCode: '61685007',
				snomedCTDescription: 'Lower limb structure',
				children: [
					{
						id: 22,
						name: 'Left Leg',
						snomedCTCode: '32153003',
						snomedCTDescription: 'Structure of left lower limb'
					},
					{
						id: 23,
						name: 'Right Leg',
						snomedCTCode: '62175007',
						snomedCTDescription: 'Structure of right lower limb'
					}
				]
			},
			{
				id: 14,
				name: 'Hands',
				snomedCTCode: '85562004',
				snomedCTDescription: 'Hand structure',
				children: [
					{
						id: 24,
						name: 'Left Hand',
						snomedCTCode: '85151006',
						snomedCTDescription: 'Structure of left hand',
						children: [
							{
								id: 28,
								name: 'Left Thumb',
								snomedCTCode: '734143007',
								snomedCTDescription: 'Structure of left thumb'
							},
							{
								id: 29,
								name: 'Left Index Finger',
								snomedCTCode: '770841009',
								snomedCTDescription: 'Structure of left index finger'
							},
							{
								id: 30,
								name: 'Left Middle Finger',
								snomedCTCode: '770884005',
								snomedCTDescription: 'Structure of left middle finger'
							},
							{
								id: 31,
								name: 'Left Ring Finger',
								snomedCTCode: '770882009',
								snomedCTDescription: 'Structure of left ring finger'
							},
							{
								id: 32,
								name: 'Left Pinky Finger',
								snomedCTCode: '762101005',
								snomedCTDescription: 'Structure of left little finger'
							}
						]
					},
					{
						id: 25,
						name: 'Right Hand',
						snomedCTCode: '78791008',
						snomedCTDescription: 'Structure of right hand',
						children: [
							{
								id: 33,
								name: 'Right Thumb',
								snomedCTCode: '734144001',
								snomedCTDescription: 'Structure of right thumb'
							},
							{
								id: 34,
								name: 'Right Index Finger',
								snomedCTCode: '770842002',
								snomedCTDescription: 'Structure of right index finger'
							},
							{
								id: 35,
								name: 'Right Middle Finger',
								snomedCTCode: '770885006',
								snomedCTDescription: 'Structure of right middle finger'
							},
							{
								id: 36,
								name: 'Right Ring Finger',
								snomedCTCode: '770883004',
								snomedCTDescription: 'Structure of right ring finger'
							},
							{
								id: 37,
								name: 'Right Pinky Finger',
								snomedCTCode: '762102003',
								snomedCTDescription: 'Structure of right little finger'
							}
						]
					}
				]
			},
			{
				id: 15,
				name: 'Feet',
				snomedCTCode: '56459004',
				snomedCTDescription: 'Foot structure',
				children: [
					{
						id: 26,
						name: 'Left Foot',
						snomedCTCode: '22335008',
						snomedCTDescription: 'Structure of left foot',
						children: [
							{
								id: 38,
								name: 'Left Big Toe',
								snomedCTCode: '723724004',
								snomedCTDescription: 'Structure of left great toe'
							},
							{
								id: 39,
								name: 'Left Second Toe',
								snomedCTCode: '1285624003',
								snomedCTDescription: 'Structure of left second toe'
							},
							{
								id: 40,
								name: 'Left Third Toe',
								snomedCTCode: '1285627005',
								snomedCTDescription: 'Structure of left third toe'
							},
							{
								id: 41,
								name: 'Left Fourth Toe',
								snomedCTCode: '770882009',
								snomedCTDescription: 'Structure of left fourth toe'
							},
							{
								id: 42,
								name: 'Left Pinky Toe',
								snomedCTCode: '895650002',
								snomedCTDescription: 'Structure of fifth toe of left foot'
							}
						]
					},
					{
						id: 27,
						name: 'Right Foot',
						snomedCTCode: '7769000',
						snomedCTDescription: 'Structure of right foot',
						children: [
							{
								id: 43,
								name: 'Right Big Toe',
								snomedCTCode: '723730004',
								snomedCTDescription: 'Structure of right great toe'
							},
							{
								id: 44,
								name: 'Right Second Toe',
								snomedCTCode: '1285623009',
								snomedCTDescription: 'Structure of right second toe'
							},
							{
								id: 45,
								name: 'Right Third Toe',
								snomedCTCode: '1285628000',
								snomedCTDescription: 'Structure of right third toe'
							},
							{
								id: 46,
								name: 'Right Fourth Toe',
								snomedCTCode: '1285633001',
								snomedCTDescription: 'Structure of right fourth toe'
							},
							{
								id: 47,
								name: 'Right Pinky Toe',
								snomedCTCode: '895651003',
								snomedCTDescription: 'Structure of fifth toe of right foot'
							}
						]
					}
				]
			}
		]
	},
	{
		id: 7,
		name: 'Skin',
		snomedCTCode: '39937001',
		snomedCTDescription: 'Skin structure'
	}
];

// recursively updates the parent ids through the tree so
// we don't need to do it by hand
const propagateParentIds = (parent: IBodyArea): void => {
	if (parent.children) {
		parent.children.forEach(child => {
			child.parendId = parent.id;
			propagateParentIds(child);
		});
	}
};

BodyAreas.forEach(area => {
	propagateParentIds(area);
});

// recurive helper function to flatten the nested list
const flattenBodyAreas = (bodyAreas: IBodyArea[]): IBodyArea[] => {
	return bodyAreas.reduce((acc: IBodyArea[], bodyArea: IBodyArea) => {
		acc.push(bodyArea);

		if (bodyArea.children) {
			acc.push(...flattenBodyAreas(bodyArea.children));
		}

		return acc;
	}, []);
};

export const FlattenedBodyAreas = flattenBodyAreas(BodyAreas);

const codeDict = keyBy(FlattenedBodyAreas, (bodyArea: IBodyArea) => bodyArea.snomedCTCode);
const nameDict = keyBy(FlattenedBodyAreas, (bodyArea: IBodyArea) => bodyArea.name);
const idDict = keyBy(FlattenedBodyAreas, (bodyArea: IBodyArea) => bodyArea.id);

// Standard way to grab bodyArea data
export const GetBodyAreaByName = (name: BodyAreaType): IBodyArea => nameDict[name];

// Getting body area from our database id
export const GetBodyAreaBySophosId = (id: number): IBodyArea => idDict[id];

// Getting body area from the snomedCT code that the FHIR database will use
export const GetBodyAreaBySnomedCode = (snomedCTCode: string): IBodyArea => codeDict[snomedCTCode];

export const GetAllDecendants = (name: BodyAreaType): IBodyArea[] => {
	const area = GetBodyAreaByName(name);
	return flattenBodyAreas(area.children ?? []);
};

export const GetAllAncestors = (name: BodyAreaType): IBodyArea[] => {
	const area = GetBodyAreaByName(name);
	const ancestors: IBodyArea[] = [];
	let current = area.parendId;
	while (current) {
		const parent = GetBodyAreaBySophosId(current);
		ancestors.push(parent);
		current = parent.parendId;
	}
	return ancestors;
};

export const GetParent = (name: BodyAreaType): IBodyArea | null => {
	const area = GetBodyAreaByName(name);
	if (!area.parendId) {
		return null;
	} else {
		return GetBodyAreaBySophosId(area.parendId);
	}
};
